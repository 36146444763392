import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import Table from "react-bootstrap/Table";
import SEO from "@components/seo";
import PageBreadcrumb from "../components/pagebreadcrumb";
import { normalizedData } from "@utils/functions";
import jsonDataInit from "../data/investors/price-sensetive-info.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../components/preloadPage/preload";
const PriceSensetiveInfo = ({ data, location, pageContext }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a7351978fc357870f0906a"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);

    const globalContent = normalizedData(data?.allGeneral?.nodes || []);

    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {preload && <Preload />}
            <SEO title="Price Sensetive Information" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Price Sensetive Information"
            />
            <Container style={{ paddingTop: "10px", paddingBottom: "60px" }}>
                <Table striped bordered hover>
                    <thead
                        style={{
                            backgroundColor: "#413565",
                            color: "#fff",
                            fontSize: "20px",
                        }}
                    >
                        <tr>
                            <th colSpan={3} style={{ textAlign: "center" }}>
                                Title
                            </th>
                            <th colSpan={3}>Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jsonData.map((tr, j) => {
                            return (
                                <tr key={j}>
                                    <td
                                        colSpan={3}
                                        style={{ textAlign: "center" }}
                                    >
                                        {tr.tdData[0]}
                                    </td>
                                    <td>
                                        <a
                                            href={tr.tdData[1]}
                                            target="_top"
                                            download
                                        >
                                            Download
                                        </a>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Container>
        </Layout>
    );
};
PriceSensetiveInfo.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query PriceSensetiveInfoQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default PriceSensetiveInfo;
